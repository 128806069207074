<template>
    <div class="btn-discount-component" :class="containerClass">
        <button
            v-if="showCode"
            type="button"
            class="btn-discount-component-code"
            :class="discountCodeClass"
            @click="() => isSupported && !info.isOnlyToView && copyText()"
        >
            <label
                v-show="copied"
                :for="info.slug + '-code-input'"
                class="btn-discount-component-coupon-copied"
                >{{ $lang.components.btnDiscount.copied }}</label
            >
            <p aria-label="code" class="text-black" :class="info.code ? textSize : 'text-sm'">
                {{ code }}
            </p>
            <img v-if="info.code" :src="discountCodeIcon" alt="icon" />
        </button>
        <template v-if="showButton && !(info.isAdmin && info.showCode)">
            <a
                v-if="info.discount && info.discount.with_digidip && !info.isAdmin"
                target="blank"
                rel="nofollow, noopener, noreferrer"
                :href="info.discount.url || ''"
                class="btn-discount-component-opener"
                :class="discountBtnClass"
            >
                <img v-if="info.mode === 'lottery'" :src="$assets.white.logout" alt="icon" />
                <span v-if="/^(coupon|offer|lottery)$/.test(info.mode)">{{ buttonText }}</span>
                <img
                    v-if="info.mode === 'offer-code'"
                    class="arrow"
                    :src="$assets.white.arrowRight"
                    alt="icon"
                />
            </a>
            <button
                v-else
                type="button"
                class="btn-discount-component-opener"
                :class="discountBtnClass"
                @click="info.buttonHandler"
            >
                <img v-if="info.mode === 'lottery'" :src="$assets.white.logout" alt="icon" />
                <span v-if="/^(coupon|offer|lottery)$/.test(info.mode)">{{ buttonText }}</span>
                <img
                    v-if="info.mode === 'offer-code'"
                    class="arrow"
                    :src="$assets.white.arrowRight"
                    alt="icon"
                />
                <img
                    v-if="info.mode === 'coupon' && info.isAdmin"
                    class="bend"
                    :src="$assets.primary.buttonBendCoupon"
                    alt="icon"
                />
            </button>
        </template>
        <template v-if="showNLink">
            <a
                v-if="info.discount && info.discount.with_digidip"
                rel="nofollow, noopener, noreferrer"
                :href="info.discount.url || ''"
                class="btn-discount-component-no-link"
                :class="discountLinkClass"
            >
                {{ buttonText }}
            </a>
            <NuxtLink
                no-prefetch
                v-else
                rel="follow"
                :to="noLinkOfferUrl"
                class="btn-discount-component-no-link"
                :class="discountLinkClass"
            >
                {{ buttonText }}
            </NuxtLink>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'
import { useClipboard } from '@vueuse/core'
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<{
            inResume?: boolean
            inCard?: boolean
            isAdmin?: boolean
            showCode?: boolean
            slug?: string
            code: string
            mode: 'offer' | 'offer-code' | 'coupon' | 'no-link-offer' | 'lottery' | 'no-link-coupon' | ''
            buttonHandler?: () => any
            isOnlyToView?: boolean
            discount: Models.Discount
        }>,
        required: true,
    },
})
const { $assets, $lang } = useNuxtApp()

const textSize = computed(() => {
    switch (true) {
        case props.info.code.length > 16:
            return 'large' + (props.info.inResume ? ' small' : '')
        case props.info.code.length > 12:
            return 'medium' + (props.info.inResume ? ' small' : '')
        case props.info.code.length > 6:
            return 'short' + (props.info.inResume ? ' small' : '')
        default:
            return 'normal' + (props.info.inResume ? ' small' : '')
    }
})

const buttonText = computed(() => {
    return props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon'
        ? $lang.components.btnDiscount.see_coupon
        : $lang.components.btnDiscount.see_offer
})

const code = computed(() => {
    return props.info.code || $lang.components.btnDiscount.no_coupon_required
})

const showCode = computed(() => {
    return (
        (!props.info.isAdmin &&
            (props.info.mode === 'offer-code' ||
                ((props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon') &&
                    props.info.showCode))) ||
        (props.info.isAdmin && props.info.showCode) ||
        (props.info.mode === 'coupon' && props.info.isOnlyToView)
    )
})

const showButton = computed(() => {
    return /^(offer-code|offer|lottery|coupon)$/.test(props.info.mode)
})

const showNLink = computed(() => {
    return (
        (props.info.mode === 'no-link-coupon' && !props.info.showCode) || props.info.mode === 'no-link-offer'
    )
})

const noLinkOfferUrl = computed(() => {
    return props.info.isOnlyToView
        ? '#'
        : props.info.mode === 'no-link-offer'
          ? `/${$lang.routes.offers}/${props.info.slug}`
          : props.info.mode === 'no-link-coupon'
            ? `/${$lang.routes.coupons}/${props.info.slug}`
            : ''
})

const containerClass = computed(() => {
    return {
        'small-button': props.info.inResume,
        'is-card': props.info.inCard,
        'is-dual': !props.info.isAdmin && (props.info.mode === 'offer-code' || props.info.mode === 'coupon'),
        'is-coupon': props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon',
    }
})

const discountCodeClass = computed(() => {
    return {
        'is-secondary-outline': props.info.mode === 'offer-code',
        'is-primary-outline': /^(coupon|no-link-coupon)$/.test(props.info.mode),
        'with-link': showCode && showButton && !(props.info.isAdmin && props.info.showCode),
    }
})

const discountBtnClass = computed(() => {
    return {
        'is-admin-coupon': props.info.mode === 'coupon' && props.info.isAdmin,
        'is-primary': /^(coupon|lottery)$/.test(props.info.mode),
        'is-secondary': /^(offer|offer-code)$/.test(props.info.mode),
        'is-dual': !props.info.isAdmin && (props.info.mode === 'offer-code' || props.info.mode === 'coupon'),
        'is-coupon': props.info.mode === 'coupon',
        'is-offer': props.info.mode === 'offer',
        'is-lottery': props.info.mode === 'lottery',
    }
})

const discountLinkClass = computed(() => {
    return {
        'is-secondary-outline': props.info.mode === 'no-link-offer',
        'is-primary-outline is-coupon': props.info.mode === 'no-link-coupon',
    }
})

const discountCodeIcon = computed(() => {
    return $assets[
        props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon' ? 'primary' : 'secondary'
    ].copyCode
})

const { copy: copyText, copied, isSupported } = useClipboard({ source: code })
</script>

<style lang="postcss">
.btn-discount-component {
    @apply w-full flex-none font-medium;
    --coupon-opener-padding-l: theme('spacing.2');
    --coupon-opener-padding-r: theme('spacing.2');
    --coupon-opener-content: theme('spacing.20');
    > * {
        @apply h-11 rounded-lg text-center;
    }

    --primary-color: var(--personalized-primary-color, theme('colors.site.primary.DEFAULT'));
    --secondary-color: var(--personalized-secondary-color, theme('colors.site.secondary.DEFAULT'));

    &.is-card {
        @apply md:w-[170px];
        &.is-dual {
            @apply md:w-60;
            &.is-coupon {
                @apply md:w-72 lg:w-80;
            }
        }
    }

    .btn-discount-component-opener {
        @apply relative flex w-full items-center justify-center text-lg;
        .bend {
            @apply absolute -right-px -top-px w-6;
        }
        .arrow {
            @apply mx-auto;
        }
        &.is-admin-coupon {
            @apply rounded-tr-xl;
        }
        &.is-dual {
            @apply w-10 flex-none;
            &.is-coupon {
                @apply min-w-max text-sm;
                padding: 0 var(--coupon-opener-padding-r) 0 var(--coupon-opener-padding-l);
                span {
                    width: var(--coupon-opener-content);
                }
            }
        }
        &.is-offer,
        &.is-dual {
            @apply rounded-lg;
        }
    }
    .btn-discount-component-coupon-copied {
        @apply absolute right-0 block h-8 w-24 transform rounded-lg border border-[--primary-color] border-opacity-25 bg-white text-sm leading-8;
        @apply ml-0 !important;
        bottom: calc(100% + 5px);
        &::after {
            @apply absolute -bottom-1 right-0 block h-2 w-2 -translate-x-full -rotate-45 transform border-b border-l border-[--primary-color] border-opacity-25 bg-white;
            content: '';
        }
    }
    &.is-dual {
        @apply flex space-x-2;
        .btn-discount-component-coupon-copied {
            @apply border-opacity-25;
            &::after {
                @apply border-opacity-25;
            }
        }
        &.is-offer {
            .btn-discount-component-coupon-copied {
                @apply border-[--secondary-color];
                &::after {
                    @apply border-[--secondary-color];
                }
            }
        }
        &.is-coupon {
            .btn-discount-component-coupon-copied {
                @apply border-[--primary-color];
                &::after {
                    @apply border-[--primary-color];
                }
            }
        }
    }
    .btn-discount-component-code {
        @apply relative flex flex-grow cursor-pointer items-center;
        p {
            @apply flex-grow cursor-pointer appearance-none overflow-hidden text-ellipsis whitespace-nowrap px-2 text-center;
            &.large {
                @apply text-xs;
                &.small {
                    @apply text-[10px];
                }
            }
            &.medium {
                @apply text-sm;
                &.small {
                    @apply text-xs;
                }
            }
            &.short {
                @apply text-base;
                &.small {
                    @apply text-sm;
                }
            }
            &.normal {
                @apply text-lg;
                &.small {
                    @apply text-base;
                }
            }
        }
        img {
            @apply mr-2 h-4 w-4;
        }
        &.with-link {
            --opener-width: calc(
                var(--coupon-opener-padding-r) + var(--coupon-opener-padding-l) +
                    var(--coupon-opener-content) + theme('spacing.4')
            );
            width: calc(100% - var(--opener-width));
        }
    }
    .btn-discount-component-no-link {
        @apply flex flex-none items-center justify-center text-lg;
    }
    .is-secondary {
        @apply border border-transparent bg-[--secondary-color] text-site-secondary-text-contrast;
    }
    .is-primary {
        @apply border border-transparent bg-[--primary-color] text-site-primary-text-contrast;
    }
    .is-secondary-outline {
        @apply border border-[--secondary-color] text-[--secondary-color];
    }
    .is-primary-outline {
        @apply border border-[--primary-color] text-[--primary-color];
    }
    &.small-button {
        &.btn-discount-component {
            > * {
                @apply h-9;
            }
            .btn-discount-component-opener {
                @apply w-28 rounded-md text-xs;
                .bend {
                    @apply w-3;
                }
                &.is-dual {
                    @apply w-6;
                }
            }
            .btn-discount-component-code {
                @apply w-36 space-x-2 rounded-md px-2 text-sm;
                img {
                    @apply h-3 w-3;
                }
            }
            .btn-discount-component-coupon-copied {
                top: calc(100% + 5px);
                &::after {
                    @apply -top-1 right-0.5 border-b-0 border-l-0 border-r border-t;
                }
            }
        }
    }
}
</style>
